import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Download from "../components/download"
import Viewport from "../components/Viewport"

import latest from "../../data/latest-wallet.json"
import pgp from "raw-loader!../../data/pgp.txt"

import "./download.scss"

function getByPlatform(platform) {
  return latest.app.downloads.filter(d => d.platform === platform)
}

function getFileName(d) {
  return d.url.substring(d.url.lastIndexOf("/") + 1)
}

function getExtension(url) {
  return url.substring(url.lastIndexOf("."))
}

const platforms = [
  {
    code: "windows",
    title: "Windows",
  },
  {
    code: "macos",
    title: "macOS",
  },
  {
    code: "linux",
    title: "Linux",
  },
]

const Page = () => {
  const release = latest

  return (
    <Layout>
      <Seo title="Download Emerald Wallet" />

      <section className="download">
        <div className="description">
          <Viewport rootMargin="0px 0px -200px 0px">
            <h1>
              Emerald <span>Wallet</span> {release.app.version}
            </h1>
          </Viewport>

          <Viewport rootMargin="0px 0px -200px 0px">
            <p>
              Download the latest version of Emerald Wallet, for desktop PCs and
              laptops. You can also check out our{" "}
              <a href="https://github.com/emeraldpay">Github</a>.
            </p>
          </Viewport>
        </div>

        <div className="downloads">
          {platforms.map(platform => {
            return (
              <div key={"download-"+platform.code}>
                <Viewport rootMargin="0px 0px -200px 0px">
                  <h2>
                    <span>Release</span> {platform.title}
                  </h2>
                </Viewport>

                {getByPlatform(platform.code).map(d => {
                  return (
                    <Viewport rootMargin="0px 0px -200px 0px" key={"ext-"+getExtension(d.url)}>
                      <Download
                        downloadUrl={d.url}
                        pgpUrl={d.pgpUrl}
                        fileName={getFileName(d)}
                        fileType={getExtension(d.url)}
                        sha256={d.sha256}
                      />
                    </Viewport>
                  )
                })}
              </div>
            )
          })}
        </div>
      </section>

      <hr />

      <section>
        <h1>Verify Download</h1>
        <p>
          Please make sure you verify the downloaded binary with PGP/GPG, to
          protect you from using a "hacked" version of the software.
        </p>
        <h4>PGP key:</h4>
        <code className={"pgp"}>
          <pre>{pgp}</pre>
        </code>
      </section>
    </Layout>
  )
}

export default Page
