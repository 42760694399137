import React from "react";
import PropTypes from "prop-types";

const Download = ({downloadUrl, pgpUrl, fileName, sha256, fileType}) => {

    const [showSecurity, setShowSecurity] = React.useState(true)

    return <div>

        <article>
            <h1>{fileName}</h1>

            <a href={downloadUrl}>
                <button onClick={() => setShowSecurity(!showSecurity)}>Download <span>({fileType})</span></button>
            </a>

            <aside className={showSecurity ? 'hidden' : ''}>

                <div className="sha"><span>SHA</span> {sha256}</div>
                <div className="pgp"><span>PGP</span> <a href={pgpUrl}>{fileName + ".asc"}</a></div>
                <div className="pgpinfo">For your security, please verify download file with the SHA and PGP</div>
            </aside>

        </article>

    </div>
}

Download.propTypes = {
    fileName: PropTypes.string,
    osName: PropTypes.string,
    className: PropTypes.string,
    pgpUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
}

export default Download